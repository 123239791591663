(function() {
    class PusherClient {
        static PAYMENT_UPDATE_EVENT = 'payment_status_change';
        static PUSHER_AUTH_BASE_PATH = '/authenticate_notification/';

        constructor() {
            this.pusher = null;
        }

        getPusher() {
            if (this.pusher) {
                return this.pusher;
            }
            return this.initializePusher();
        }

        subscribePaymentStatusUpdates(customerGuid, leaseNumber, onSuccessCallback, onErrorCallback) {
            const pusher = this.getPusher();
            const channelName = `private-${window.railsEnv}-lease-${leaseNumber}`;
            pusher.config.authEndpoint = PusherClient.PUSHER_AUTH_BASE_PATH.concat(customerGuid);
            pusher.connect();
            const channel = pusher.subscribe(channelName);
            channel.bind(PusherClient.PAYMENT_UPDATE_EVENT, (data) => {
                if (data.status !== 'processing') {
                    onSuccessCallback(leaseNumber, data);
                }
            });
            channel.bind('pusher:subscription_error', (status) => {
                onErrorCallback(status);
            });
        }

        disconnect() {
            if (this.isConnected()) {
                this.pusher.disconnect();
            }
        }

        isConnected() {
            return this.pusher && this.pusher.connection.state !== 'disconnected';
        }

        initializePusher() {
            const pusherKey = window.pusherKey;
            const pusherCluster = window.pusherCluster;
            this.pusher = new Pusher(pusherKey, {
                cluster: pusherCluster,
                forceTLS: true
            });
            return this.pusher;
        }
    }

    window.PusherClient = new PusherClient();
})();